import React, { useState, useCallback } from 'react'
import fetch from 'unfetch'
import { navigate } from 'gatsby'
import { useLoads } from 'react-loads'
import Timeout from 'await-timeout'
import {
  FormControl,
  FormLabel,
  Checkbox,
  Input,
  Button,
  Heading,
  Text,
  Container,
  Stack,
  HStack,
  Flex,
  Link,
  CheckboxGroup,
} from '@chakra-ui/react'
import ReactRouterLink from '../link'
import 'react-phone-number-input/style.css'
import PhoneInput, {
  isValidPhoneNumber,
} from 'react-phone-number-input'
import { ErrorHandling } from '../../utils/error'

import { UpdateCustomer } from '../../utils/updateCustomer'
import { setCustomerInState } from '../../context/store-context'
import { PasswordSchema } from '../../utils/schema'

export const Register = ({ path }: { path: string }) => {
  const updateCustomerInState = setCustomerInState()
  const [passwordField1, setPasswordField1] = useState('')
  const [passwordField2, setPasswordField2] = useState('')
  const form = React.createRef() as React.RefObject<HTMLFormElement>
  const [attempts, setAttempts] = useState(0)
  const [value, setValue] = useState('')
  const [toggleCheck, setToggleCheck] = useState(false)
  const [processingMessage, setProcessingMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [completed, setCompleted] = useState(false)

  function wait(ms = 0) {
    return new Promise((resolve, reject) => {
      setTimeout(resolve, ms)
    })
  }
  

  const handleRegister = useCallback(
    async (email: string, password: any, firstName: any, lastName: any, phone: any, toggleCheck: any, token : any) => {
      setAttempts(attempts + 1)
      setLoading(true)
      setProcessingMessage('Registering')

      if (!PasswordSchema.validate(passwordField1)) {
        setLoading(false)
        throw new Error(
          'Your password should be between 8 and 100 characters, and have at least one lowercase and one uppercase letter.'
        )
      }

      if (passwordField1 !== passwordField2) {
        setLoading(false)
        await Timeout.set(400)
        throw new Error('Passwords do not match.')
      }

      


      const res = await fetch(
        `${process.env.GATSBY_SERVERLESS_BASE}/register`,
        {
          method: 'POST',
          body: JSON.stringify({
            email,
            password,
            firstName,
            lastName,
            phone,
            acceptsMarketing: toggleCheck,
            token
          }),
        }
      )
      try {
        const customer = await res.json()

        if (customer.error) {
          console.error(customer.error)
          
          throw new Error('Something went wrong, sorry for the inconvenience: ' + customer.error)
        } else if (res.ok ) {
          setProcessingMessage('Registration successful, please check your email for confirmation')            
          await wait(5000)
          if(res.status != 200){
            UpdateCustomer(customer, email)
            updateCustomerInState()
          }
          setCompleted(true)
          setLoading(false)
          setTimeout(() => {
            navigate('/')
          }, 2000)
        }
      } catch (err) {
        await wait(4000)
        setLoading(false)
        throw err
      }
    },
    [passwordField1, passwordField2, attempts]
  )
  const { error, isRejected, load } = useLoads(
    'handleRegister',
    handleRegister as any,
    {
      defer: true,
    }
  )

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const formData = new FormData(e.currentTarget);
    const token = formData.get('cf-turnstile-response') as string;
    const { email, password, firstName, lastName, phone } =
      form!.current!.elements
    load(
      email.value,
      password.value,
      firstName.value,
      lastName.value,
      phone.value,
      toggleCheck,
      token
    )
  }
  return (
    <Container py={5} maxW="container.sm">
      
      <form onSubmit={(e) => handleSubmit(e)} ref={form}>
        <Stack spacing={4}>
          <Heading as="h5">Sign Up</Heading>

          {/* {(isPending || isReloading) && <span>Loading</span>} */}

          
          <HStack align="flex-start">
            <FormControl mb={4} isRequired>
              <FormLabel htmlFor="firstname">First Name</FormLabel>

              <Input
                type="text"
                name="firstName"
                id="firstname"
                placeholder={'First name'}
              />
            </FormControl>
     
            <FormControl mb={4} isRequired>
              <FormLabel htmlFor="lastname">Last Name</FormLabel>

              <Input
                type="text"
                name="lastName"
                id="lastname"
                placeholder={'Last name'}
              />
            </FormControl>
          </HStack>

          <HStack align="flex-start">
            <FormControl mb={4} isRequired>
              <FormLabel htmlFor="email">Email</FormLabel>

              <Input
                type="text"
                name="email"
                id="email"
                placeholder={'Enter Email'}
                autoComplete="email"
              />
            </FormControl>

            <FormControl mb={4}>
              <FormLabel htmlFor="phone">Phone (optional)</FormLabel>

              <Input
                as={PhoneInput}
                name="phone"
                id="phone"
                placeholder={'Phone number'}
                value={value}
                international={true}
                defaultCountry="GB"
                withCountryCallingCode={true}
                onChange={() => setValue}
                error={
                  isValidPhoneNumber(value)
                      ? undefined
                      : 'Invalid phone number'
                    
                }
              />

            </FormControl>
           
          </HStack>
         
         
         
          <FormControl mb={4} isRequired>
            <FormLabel htmlFor="password">Password</FormLabel>

            <Input
              type="password"
              name="password"
              id="password"
              placeholder={'Password'}
              value={passwordField1}
              onChange={(e) => setPasswordField1(e.target.value)}
            />
          </FormControl>
         
          <FormControl mb={4} isRequired>
            <FormLabel htmlFor="password2">Confirm Password</FormLabel>

            <Input
              type="password"
              name="passwordConfirm"
              id="password2"
              placeholder={'Verify Password'}
              value={passwordField2}
              onChange={(e) => setPasswordField2(e.target.value)}
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel htmlFor="acceptsMarketing">Would you like to receive news about latest news and offers?</FormLabel>
            <Checkbox
              name="acceptsMarketing"
              id="acceptsMarketing"
              onChange={() => setToggleCheck(toggleCheck ? false : true)}
            />
          </FormControl>
          <Flex alignItems="center">
            <Button type="submit" mr={2} isLoading={loading} isDisabled={completed} loadingText='Registering' colorScheme="brand.brown">
              {/* {isPending || isReloading ? (
                <span>Loading</span>
              ) : (
                <span>Submit</span>
              )} */}
              {completed ? "Registration complete" : "Register"}
            </Button>
            <Text>
              Already have an account?{' '}
              <Link as={ReactRouterLink} variant="inline" to="/account/login">
                Log in
              </Link>
            </Text>
          </Flex>
        </Stack>
        {isRejected && <ErrorHandling error={error.message} />}
        {completed && <Text fontWeight="bold">Registration successful</Text>}
        <div className="cf-turnstile" data-sitekey="0x4AAAAAAAVX2BYOL1V8-uf0"></div>
      </form>
      
    </Container>
  )
}
