import React, { useContext, useEffect } from 'react'
import { navigate } from 'gatsby'
import cookie from 'js-cookie'
import fetch from 'unfetch'
import LoyaltyLionContext from '../../context/loyaltylion-context'

import { setCustomerInState, useStore, StoreContext } from '../../context/store-context'
import { Orders } from './orders'
import { Rewards } from '../loyaltylion/rewards'
import {
  Button,
  Heading,
  SimpleGrid,
  Container,
  Tabs,
  Tab,
  TabList,
  TabPanel,
  TabPanels
} from '@chakra-ui/react'
import { FiArrowRight, FiArrowLeft } from 'react-icons/fi'
import Seo from '../seo'

export const Portal = () => {
  const updateCustomerInState = setCustomerInState()
  const { customerToken } = useStore()
  const { checkout, loading } = useContext(StoreContext)
  const [initialised, setInitialised] = useContext(LoyaltyLionContext)['initState']
  const [authenticated, setAuthenticated] = useContext(LoyaltyLionContext)['authState']

  const logout = (e: React.MouseEvent<HTMLAnchorElement>, updateCustomer: any) => {
    e.preventDefault()
    const customerToken = cookie.get('customer_token')
    fetch(`/.netlify/functions/logout`, {
      method: 'POST',
      body: JSON.stringify({
        accessToken: customerToken
      })
    })
    .then(() => {
      cookie.remove('customer_token' )
      cookie.remove('customer_email')
      setTimeout(() => {
        updateCustomerInState()
        initialised && loyaltylion.logoutCustomer() // eslint-disable-line no-undef
        
      }, 300)
      setTimeout(() => {
        navigate('/')
      }, 500)
    })
  }

  useEffect(() => {
    if(typeof loyaltylion !== 'undefined'){ // eslint-disable-line no-undef
      if(typeof loyaltylion.ui !== 'undefined'){ // eslint-disable-line no-undef
        initialised && loyaltylion.ui.refresh() // eslint-disable-line no-undef
      }
    }
  }, [authenticated, updateCustomerInState])
  

  const handleCheckout = () => {
    window.open(
      checkout.webUrl.replace(
        'bestmudpies.myshopify.com',
        'checkout.mudfoods.com'
      ),
      '_self'
    )
    //window.open(checkout.webUrl, '_self')
  }

  return (
    <Container py={5} maxW="container.lg">
      <Seo title="Account Login | Mud Foods" description="Login to your Mud Foods Account here."/>
      <Heading as="h1" fontSize="lg">Your Account</Heading>
      {customerToken ? (
        <>      <Tabs mt={2}>
        <TabList>
          <Tab>Rewards</Tab>
          <Tab id="previous-orders">Previous Orders</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Rewards />
          </TabPanel>
          <TabPanel>
            <Orders />
          </TabPanel>
        </TabPanels>
      </Tabs>
      
      
      <SimpleGrid columns={3} spacing={[1, 2, 10]} minChildWidth={'100px'}>
      <Button
          colorScheme="brand.green"
          onClick={() => navigate('/')}
          disabled={loading}
          leftIcon={<FiArrowLeft />}
          sx={{'white-space': 'normal'}}
        >
          Continue shopping
        </Button>
        <Button
        colorScheme="brand.asparagus"
        onClick={e => logout(e, updateCustomerInState)}>
         Logout
      </Button>
      <Button
          colorScheme="brand.brown"
          onClick={handleCheckout}
          disabled={loading}
          rightIcon={<FiArrowRight />}
        >
          Checkout
        </Button>
      </SimpleGrid>
      </>

      ):
      ""}
    </Container>
  )
}

export default Portal