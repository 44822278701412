import React, { useState, useCallback } from "react"
import Helmet from "react-helmet"
import fetch from "unfetch"
import { useLoads } from 'react-loads'
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  Heading,
  Text,
  Container,
  Stack,
  Link,
} from '@chakra-ui/react'
import ReactRouterLink from '../link'

import { ErrorHandling } from '../../utils/error'

export const ForgotPassword = ({ path }: { path: string }) => {
  const [formSuccess, setFormSuccess] = useState(false)
  const form = React.createRef() as React.RefObject<HTMLFormElement>

  const handleForgot = useCallback(
    (email) =>
      fetch(`/.netlify/functions/forgot-password`, {
        method: "POST",
        body: JSON.stringify({
          email
        }),
      })
        .then(res => res.json())
        .then(res => {
          if (res.error) {
            throw new Error(res.error)
          } else {
            setFormSuccess(true)
          }
        }),
    []
  )

  const { error, isRejected, isPending, isReloading, load } = useLoads(
    "handleForgot",
    handleForgot as any,
    {
      defer: true,
    }
  )

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
      const { email } = form.current.elements
      console.log(email.value)
      load(email.value)
    
  }

  return (
    <Container py={5} maxW="container.sm">
      <Helmet title="forgot password" />

        <form className="f col jcc x aic y" onSubmit={e => handleSubmit(e)} ref={form}>
          <Stack spacing={4}>
            <Heading as="h5">Forgot your password</Heading>

          {isRejected && <ErrorHandling error={error.message} />}

          {formSuccess && (
            <Text>
              Got it! Email coming your way now.
            </Text>
          )}

          <div>
            {/* <div className="pb1 mb1 pya">
              <label htmlFor='email' className="caps sans ls my05">Email</label>
              <input id='email' name="email" type="text" required={true} className="accounts__input py1 px1 x s16 mb1" placeholder="Enter Email" />
            </div> */}
            <FormControl mb={4} isRequired>
              <FormLabel htmlFor="email">Email</FormLabel>

              <Input
                type="text"
                name="email"
                id="email"
                placeholder={'Enter Email'}
     
              />
            </FormControl>
            <div>
              <Button type="submit"
              mr={2}
              colorScheme="brand.brown">
                {(isPending ||
                  isReloading) ? (
                  <span>Loading</span>
                ): (
                  <span>Request Reset</span>
                )}
              </Button>
            </div>
          </div>

          <Text>
            Remember your password?{" "}
            <Link as={ReactRouterLink} variant="inline" to="/account/login">
              Login
            </Link>
          </Text>
          </Stack>
        </form>
    </Container>
  )
}
