import React, { useCallback, useState } from "react"
import Helmet from "react-helmet"
import { useDeferredLoads } from "react-loads"
import fetch from "unfetch"
// @ts-ignore
import { encode } from "shopify-gid"
import { navigate } from "@reach/router"
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  Heading,
  Text,
  Container,
  Stack,
  Flex,
  Link,
} from '@chakra-ui/react'
import ReactRouterLink from '../link'
import Timeout from "await-timeout"

import { PasswordSchema } from '../../utils/schema'

const Activate = (props: { id: string; token: string }) => {
  const [passwordField1, setPasswordField1] = useState("")
  const [passwordField2, setPasswordField2] = useState("")
  const [attempts, setAttempts] = useState(0)

  const verifyAccount = useCallback(
    async e => {
      setAttempts(attempts + 1)

      if (passwordField1 !== passwordField2) {
        await Timeout.set(400)
        throw new Error("Passwords do not match.")
      }

      if (!PasswordSchema.validate(passwordField1)) {
        throw new Error(
          "Your password should be between 8 and 100 characters, and have at least one lowercase and one uppercase letter."
        )
      }

      const body = {
        id: encode("Customer", props.id),
        input: {
          activationToken: props.token,
          password: passwordField1,
        },
      }

      const res = await fetch(`${process.env.GATSBY_SERVERLESS_BASE}/activate`, {
        method: "POST",
        body: JSON.stringify(body),
      })

      try {
        const customer = await res.json()

        if (customer.error) {
          const err = new Error("Server Error: Account verification fetch response error.")

          throw err
        } else if (!res.ok) {
          const err = new Error("Server Error: Account verification fetch not OK.")

     
          throw err
        }

        await Timeout.set(400)
        await navigate("/account/login")
      } catch (err) {
        throw err
      }
      // hacky way to get it to run anew every time
      // and maybe in the future redirect them if they
      // need help.
    },
    [passwordField1, passwordField2, attempts]
  )

  const { error, load, isReloading, isPending } = useDeferredLoads(
    "verifyAccount",
    verifyAccount,
    {}
  )

  return (
    <Container py={5} maxW="container.sm">
      <Helmet title="activate account" />
        <Stack spacing={4}>
          <Heading as="h5">Activate Account</Heading>
          <Heading as="h6" fontSize="xl">Almost there.</Heading>
            
   
          <div>
            {error && !isReloading && (
              <Text className="studio mt1 error f col">
                <span>{error.message}</span>
              </Text>
            )}

            {(isPending || isReloading) && (
              <div className="f jcc p1">
                <span>Loading</span>
              </div>
            )}

            <div
              className={
                isPending || isReloading ? "invisible" : "visible"
              }
            >
              <FormControl mb={4} isRequired>
              <FormLabel htmlFor="password">Password</FormLabel>

              <Input
                type="password"
                name="password"
                id="password"
                placeholder={'Password'}
                value={passwordField1}
                onChange={e => setPasswordField1(e.target.value)}
              />
            </FormControl>

            <FormControl mb={4} isRequired>
              <FormLabel htmlFor="password2">Confirm Password</FormLabel>

              <Input
                type="password"
                name="passwordConfirm"
                id="password2"
                placeholder={'Verify Password'}
                value={passwordField2}
                onChange={e => setPasswordField2(e.target.value)} 
              />
            </FormControl>

            </div>
            <Flex align="center">
              
              <Button
                type="submit"
                mr={2}
                colorScheme="brand.brown"
                onClick={load}
              >
                Activate
              </Button>

              <div>
                <Text>
                  Already have an account?{" "}
                  <Link as={ReactRouterLink} variant="inline" to="/account/login">
                    Login
                  </Link>
                </Text>
              </div>
            </Flex>
          </div>
      </Stack>
    </Container>
  )
}

export default Activate
