import React from 'react'

export const Rewards = () => {

  return (
    <> 
      <div data-lion-integrated-page=""></div> 
    </> 
  )
}

export default Rewards