import React, { useState, useCallback } from 'react'
import fetch from 'unfetch'
import { encode } from 'shopify-gid'
import { useLoads } from 'react-loads'
import { navigate } from 'gatsby'
import Timeout from 'await-timeout'
import {
  FormControl,
  Box,
  FormLabel,
  Input,
  Button,
  Heading,
  Text,
  Container,
  Stack,
  Flex,
} from '@chakra-ui/react'

import { ErrorHandling } from '../../utils/error'
import { PasswordSchema } from '../../utils/schema'

export const Reset = (props: {
  path: string
  id?: string
  token?: string
}) => {
  const [passwordField1, setPasswordField1] = useState("")
  const [passwordField2, setPasswordField2] = useState("")
  const [submit, setSubmitting] = useState(false)
  const [formSuccess, setFormSucces] = useState(false)
  const form = React.createRef() as React.RefObject<HTMLFormElement>

  const handleReset = useCallback(
    async (password) =>  {

      if (!PasswordSchema.validate(passwordField1)) {
        throw new Error(
          "Your password should be between 8 and 100 characters, and have at least one lowercase and one uppercase letter."
        )
      }

      if (passwordField1 !== passwordField2) {
        await Timeout.set(400)
        throw new Error("Passwords do not match.")
      }
        fetch(`/.netlify/functions/reset-password`, {
          method: 'POST',
          body: JSON.stringify({
            id: encode('Customer', props.id),
            input: {
              resetToken: props.token,
              password
            }
          })
        })
          .then(res => res.json())
          .then(res => {
            if (res.error) {
              throw new Error(res.error)
              setSubmitting(false)
            } else {
              setFormSucces(true)
              // UpdateCustomer(res, res.customer.email)
              // re-hydrate the cart so it contains the email
              // checkout.hydrate()
              setTimeout(() => {
                navigate('/account/login')
              }, 400)
            }
          })
        },
    [passwordField1, passwordField2]
  )

  const { error, isRejected, isPending, isReloading, load } = useLoads(
    "handleReset",
    handleReset as any,
    {
      defer: true
    }
  )

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    setSubmitting(true)
    const { password } = form!.current!.elements
    load(password.value)
  }
  return (
    <Container py={5} maxW="container.sm">
          <form className='f col jcc aic y' onSubmit={e => handleSubmit(e)} ref={form}>
          <Stack spacing={4}>
            <Heading as="h5">Reset your password</Heading>
            <Heading as="h2">Let's get you logged back in.</Heading>
      
            {(isPending ||
              isReloading) && (
              <span>Loading</span>
            )}

            {isRejected && <ErrorHandling error={error.message} />}

            <Box>
              {formSuccess && (
                <Text className='small mt1'>Got it! Email coming your way now.</Text>
              )}
              <FormControl mb={4} isRequired>
                <FormLabel htmlFor="password">Password</FormLabel>

                <Input
                  type="password"
                  name="password"
                  id="password"
                  placeholder={'Password'}
                  value={passwordField1}
                  onChange={(e) => setPasswordField1(e.target.value)}
                />
              </FormControl>
            
              <FormControl mb={4} isRequired>
                <FormLabel htmlFor="password">Confirm Password</FormLabel>

                <Input
                  type="password"
                  name="passwordConfirm"
                  id="password2"
                  placeholder={'Verify Password'}
                  value={passwordField2}
                  onChange={(e) => setPasswordField2(e.target.value)}
                />
              </FormControl>
           
              <Flex align="center">
              <Button type="submit" mr={2} colorScheme="brand.brown">{submit ? 'Resetting' : 'Reset Password'}</Button>
              </Flex>
            </Box>
            </Stack>
          </form>
  </Container >

  )
}
