import React, { useEffect, useCallback, useState, useContext } from 'react'
import cookie from 'js-cookie'
import { useLoads } from 'react-loads'
import spacetime from 'spacetime'
import { navigate } from 'gatsby'
import {
  Button,
  Heading,
  Text,
  Box,
  SimpleGrid,
  GridItem,
  Tooltip,
  Flex,
} from '@chakra-ui/react'
import { StoreContext, useStore } from '../../context/store-context'

export const Orders = () => {
  const { addVariantToCart } = useContext(StoreContext)
  const { customerToken } = useStore()
  const [orders, setOrders] = useState([])
  const handleOrders = useCallback(
    (token) =>
      fetch(`/.netlify/functions/orders`, {
        method: 'POST',
        body: JSON.stringify({
          token,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.error) {
            throw new Error("orders: " + res.error.message)
          } else {
    
            if (res.customer.orders.edges) {
              setOrders(res.customer.orders.edges)
            }
            return null
          }
        }),
    []
  )

  const addOrderToCart = (lineItems) => {
    lineItems.map((item) => {
      addVariantToCart(item.node.variant.id, item.node.quantity)
    })
  }

  const { error, isRejected, isPending, isReloading, load } = useLoads(
    'handleOrders',
    handleOrders as any,
    {
      defer: true,
    }
  )

  useEffect(() => {
    // const token = customerToken || cookie.get('customer_token')
    const token = cookie.get('customer_token')
    if (token) {
      load(token)
    }
  }, [])

  return (
    <Box pt={5} pb={5}>
      <Heading as="h2" fontSize="md">
        Orders
      </Heading>
      <Box>
        {(isPending || isReloading) && <span>Loading</span>}

        {isRejected && (
          <div className="mt1 error">
           
            {error.message}
          </div>
        )}

        {orders.length > 0 ? (
          <Box mb={2} mt={2}>
            {orders.map((order) => (
              <Box
                mb={2}
                key={"order+" + order.node.orderNumber}
                borderWidth="1px"
                borderRadius="lg"
                overflow="hidden"
                backgroundColor="white"
                _hover={{ backgroundColor: 'brand.gray.50' }}
              >
                <SimpleGrid p={6} columns={[2, 3, 3]}>
                  <GridItem mr={2}>
                    <Heading as="h5" fontSize="sm" mr={2}>
                      Order Number
                    </Heading>
                    <Text>{order.node.orderNumber}</Text>
                  </GridItem>
                  <GridItem mr={2}>
                    <Heading as="h5" fontSize="sm" mr={2}>
                      Processed at
                    </Heading>
                    <Text>
                      {spacetime(order.node.processedAt).unixFmt('dd.MM.yyyy')}
                    </Text>
                  </GridItem>
                 
                  <GridItem>
                    <Flex wrap="wrap">
                      <Box mr={2} mb={2}>
                        <Button
                          colorScheme="brand.brown"
                          onClick={() => navigate(order.node.statusUrl)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Order Status
                        </Button>
                      </Box>
                      <Box mr={2}>
                        <Tooltip
                          label="Add these same items to your basket"
                          placement="bottom"
                        >
                          <Button
                            colorScheme="brand.green"
                            onClick={() =>
                              addOrderToCart(order.node.lineItems.edges)
                            }
                          >
                            Add items to basket
                          </Button>
                        </Tooltip>
                      </Box>
                    </Flex>
                  </GridItem>
                  <GridItem p={4} rowSpan={1} colSpan={[2, 3, 4]}>
                    <Box>
                      <Heading as="h6" fontSize="sm">
                        Items:
                      </Heading>
                    </Box>
                    {order.node.lineItems.edges.map((lineItem, index) => (
                      <Box key={index + lineItem.node.title}>
                        <Text>
                          - {lineItem.node.quantity} x {lineItem.node.title}
                        </Text>
                      </Box>
                    ))}
                  </GridItem>
                </SimpleGrid>
              </Box>
            ))}
          </Box>
        ) : (
          (!isPending || !isReloading) && (
            <Text>Sorry you don't have any orders yet!</Text>
          )
        )}
      </Box>
    </Box>
  )
}
