import React, { useEffect, useCallback, useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import fetch from 'unfetch'
import { navigate } from 'gatsby'
import Helmet from 'react-helmet'
import { useLoads } from 'react-loads'
import cookie from 'js-cookie'
import {
  FormControl,
  FormLabel,
  Input,
  Box,
  Button,
  Heading,
  Text,
  Container,
  Stack,
  Link,
  SimpleGrid,
} from '@chakra-ui/react'
import ReactRouterLink from '../link'

import { ErrorHandling } from '../../utils/error'

import { UpdateCustomer } from '../../utils/updateCustomer'
import { setCustomerInState } from '../../context/store-context'

export const Login = ({ path }: { path: string }) => {
  const updateCustomerInState = setCustomerInState()
  const localStorageKey = `shopify_checkout_id`
  const checkoutId = localStorage.getItem(localStorageKey)
  const form = React.createRef() as React.RefObject<HTMLFormElement>
  const [ready, setReady] = useState(false)

  // useEffect(() => {
  //   if (cookie.get('customer_token') || cookie.get('customer_email')) navigate('/account')
  //   setReady(true)
  // }, [0]);

  const handleLogin = useCallback(
    (email, password) =>
      fetch(`/.netlify/functions/login`, {
        method: 'POST',
        body: JSON.stringify({
          email,
          password,
          checkoutId,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.error) {
            throw new Error('Login:' + res.error)
          } else {
            UpdateCustomer(res, email)
            setTimeout(() => {
              updateCustomerInState()
              navigate('/account')
            }, 400)
            return null
          }
        }),
    []
  )

  const { error, isRejected, isPending, isReloading, load } = useLoads(
    'handleLogin',
    handleLogin as any,
    {
      defer: true,
    }
  )

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const { email, password } = form.current.elements

    load(email.value, password.value)
  }

  return (
    <Container py={5} maxW="container.lg">
      <Helmet title="login" />
      <SimpleGrid columns={[1, 1, 2]} spacing={4}>
        <Box>
          <form onSubmit={(e) => handleSubmit(e)} ref={form}>
            <Stack spacing={4}>
              <Heading as="h5">Log In</Heading>

              <Box>
                {(isPending || isReloading) && <span>Loading</span>}

                {isRejected && <ErrorHandling error={error.message} />}

                <FormControl mb={4} isRequired>
                  <FormLabel htmlFor="email">Email</FormLabel>

                  <Input
                    type="text"
                    name="email"
                    id="email"
                    placeholder={'Enter Email'}
                    autoComplete="email"
                  />
                </FormControl>

                <FormControl mb={4} isRequired>
                  <FormLabel htmlFor="password">Password</FormLabel>

                  <Input
                    type="password"
                    name="password"
                    id="password"
                    placeholder={'Enter Password'}
                  />
                </FormControl>

                <Box>
                  <Button type="submit" mr={2} colorScheme="brand.brown">
                    {isPending || isReloading ? (
                      <span>Loading</span>
                    ) : (
                      <span>Log in</span>
                    )}
                  </Button>
                </Box>
              </Box>

              <Box>
                <Box>
                  <Link
                    as={ReactRouterLink}
                    variant="inline"
                    to="/account/forgot"
                  >
                    Forgot password?
                  </Link>
                </Box>
                <Box>
                  Don't have an account?{' '}
                  <Link
                    as={ReactRouterLink}
                    variant="inline"
                    to="/account/register"
                  >
                    Sign up
                  </Link>
                </Box>
              </Box>
            </Stack>
          </form>
        </Box>
        <ReactRouterLink to="/account/register">
        <Box bg="#D48238">
          <StaticImage
            src="../../images/pie-perks.png"
            alt="Pie perks from Mud Foods"
            formats={['auto', 'webp']}
            placeholder="none"
          />
          <Box p={8} color="#fff">
          <Text fontSize="large" >Unlock delicious rewards - Join now for instant discounts. Exclusive perks & more!</Text>
          <Text fontSize="2xl" fontWeight={'bold'}>Sign up now!</Text>
          </Box>
        </Box>
        </ReactRouterLink>
      </SimpleGrid>
    </Container>
  )
}
