import cookie from "js-cookie"

export const UpdateCustomer = (res: {
  token: string
  // customer: {
  //   firstName: string
  // }
}, email: string) => {
  cookie.set("customer_token", res.token, { expires: 90 })
  // cookie.set("customer_firstName", res.customer.firstName, {
  //   expires: 90,
  // })
  cookie.set("customer_email", email, { expires: 90 })
}