import React, { useEffect } from 'react'
import { Router } from '@reach/router'
import {
  Container,
  Heading
} from '@chakra-ui/react'
import AuthWrapper from '../components/auth/authWrapper'
// @ts-ignore
import Activate from '../components/auth/activate'
import Layout from '../components/layout'
import { ForgotPassword } from '../components/auth/forgotPassword'
import { Register } from '../components/auth/register'
import { Login } from '../components/auth/login'
import { Reset } from '../components/auth/reset'
import { InvalidToken } from '../components/auth/invalid_token'
import { Portal } from '../components/auth/portal'
import Seo from '../components/seo'

const Account = ({
  pageContext: { breadcrumb }
}) => {
  return (
    <Layout breadcrumb={breadcrumb}>
      <Seo
        title={"Your account"}
        description={"View information about previous purchases, quick add to cart and rewards earned"}
      />
      <Container py={20}>
      <Heading as="h1">Your Account</Heading>
      <Router basepath="/account">
        {/* <PublicRoute path='/account'> */}
          <AuthWrapper path='/' component={Portal} />
          <Reset path='/reset/:id/:token' />
          <Login path='/login' />
          <Activate
            // @ts-ignore
            path='/activate/:id/:token'
          />
          <InvalidToken path='/invalid_token' />
          <Register path='/register' />
          <ForgotPassword path='/forgot' />
        {/* </PublicRoute> */}
      </Router>
    </Container>
    </Layout>
  )
}

function PublicRoute(props: { children: React.ReactNode; path: string }) {
  return <div>{props.children}</div>
}

export default Account
